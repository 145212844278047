.homepage-search {
    .SumoSelect {
        &.sumo_type {
            .CaptionCont {
                display: block !important;
            }
        }
    }
}

.search-selects {
    background: transparent;
    padding: 0;
    color: #1b262e;
    font-size: 13px;

    .input-column {
        height: 50px;

        input {
            padding: 0 15px;
            width: 112px;
            height: 50px;
            border: none;
        }
    }

    .select-column {
        width: 141px;
        height: 50px;

        .SumoSelect {
            > .CaptionCont {
                border: none;
                margin-right: 1px;
                width: 140px;
                text-align: left;

                > span {
                    height: 50px;
                    line-height: 50px;

                    &.placeholder {
                        color: #1b262e;
                        font-size: 13px;
                        font-style: normal;
                    }
                }

                > label {
                    > i {
                        line-height: 50px;
                    }
                }
            }

            > .optWrapper {
                top: 51px;
                border: none;

                .options {
                    li {
                        text-align: left;

                        label {
                            position: relative;
                            top: -1px;
                        }

                        &.opt {
                            padding-left: 30px !important;

                            span {
                                width: 20px;
                                margin-left: -25px;
                            }
                        }
                    }
                }
            }
        }
    }

    .range-col {
        background: #FFF;
        height: 50px;

        > p {
            color: #1b262e;
        }
    }
}